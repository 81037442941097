<template>
  <div>
    <img class="loading-animation" src="../icons/loading-screen.png" alt="" />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
@import "../scss/variable.scss";
.loading-animation {
  width: 100px;
  height: 100px;

  animation: 1.5s big-rotation cubic-bezier(0.25, 0.92, 0.18, 0.99) infinite;
  transform-origin: center;

  @keyframes big-rotation {
    to {
      transform: rotateZ(360deg);
    }
  }
}
</style>
