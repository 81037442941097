import { UploadableFile } from "@/classes/UploadableFile";
import { constants } from "@/compositions/constants";
import { Ref, ref } from "vue";
import { createStore } from "vuex";
import { displayError, fileExists, formatSize } from "@/compositions/functions";

const defaultState = () => {
  return {
    errors: [] as string[],
    files: [] as UploadableFile[],
    email_from: "",
    upload_percentage: 0,
    uploaded: false,
    dl_error: "",
    uploading: false,
    upload_size: 0,
    starting_time: 0,
    remaining_time: Infinity,
    updateTime: true,
    params: {
      message: "",
      title: "",
      validity: 30,
      dl_max: 250,
      password: {
        protected: false,
        pass: "",
      },
    },
  };
};

export default createStore({
  state: defaultState(),
  getters: {},
  mutations: {
    addError(state, error) {
      state.errors.push(error);
    },
    addFiles(state, newFiles: File[]) {
      if (state.files.length + newFiles.length > constants.max_files) {
        displayError(
          `Nombre maximal de fichiers atteint (max: ${constants.max_files})`
        );
        return;
      }

      const newUploadableFiles: UploadableFile[] = [];
      let total_size = 0;

      newFiles.forEach((file) => {
        const newUp = new UploadableFile(file);
        total_size += file.size;
        if (fileExists(state.files, file.name)) {
          displayError(
            `Le fichier suivant apparaît deux fois: ${file.name}. Veuillez n'envoyer que des fichiers ayant des noms uniques.`
          );
          return;
        }
        newUploadableFiles.push(newUp);
      });
      if (total_size > constants.max_size) {
        displayError(
          `Taille maximale atteinte. Taille actuelle  ${formatSize(
            total_size
          )} - Taille maximale: ${formatSize(constants.max_size)}`
        );
        return;
      }

      state.files = state.files.concat(newUploadableFiles);
    },
    deleteFile(state, index: number) {
      state.files.splice(index, 1);
    },
    // addMail(state, email: string) {
    //   state.email_destination.push(email);
    // },
    // deleteMail(state, index: number) {
    //   state.email_destination.splice(index, 1);
    // },
    change_upload_percentage(state, new_p) {
      state.upload_percentage = new_p;
    },
    change_uploaded(state, new_up) {
      state.uploaded = new_up;
    },
    reset_state(state) {
      Object.assign(state, defaultState());
    },
  },

  actions: {},
  modules: {},
});
