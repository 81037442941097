import { UploadableFile } from "@/classes/UploadableFile";
import axios from "axios";
import store from "@/store";
import { constants } from "./constants";
import { displayError, formatWithNDecimal } from "./functions";

export async function uploadFile(type_mail: boolean, mail_from: string) {
  // set up the request data
  const formData = new FormData();
  // console.log(formData);
  formData.append("mail_from", JSON.stringify(mail_from));
  formData.append("mail_dest", JSON.stringify([constants.oa_mail]));

  formData.append("type_mail", JSON.stringify(type_mail));
  formData.append("oa_r", JSON.stringify(true));
  // store.state.params.validity = 0.0006944444444;
  formData.append("params", JSON.stringify(store.state.params));
  store.state.files.forEach((file) => {
    formData.append("file", file.file);
  });
  // console.log(file.file);
  // track status and upload file
  // file.status = "loading";
  const config = {
    onUploadProgress: function (progressEvent: ProgressEvent) {
      const percentCompleted = formatWithNDecimal(
        (progressEvent.loaded * 100) / progressEvent.total,
        0
      );
      // file.uploadPercentage = percentCompleted;
      store.state.upload_percentage = percentCompleted;

      const currentTime = new Date().getTime();
      const diff = (currentTime - store.state.starting_time) / 1000;

      const debitactuel = progressEvent.loaded / diff; //en bits/s
      const remainingTime =
        (progressEvent.total - progressEvent.loaded) / debitactuel;

      // console.log(Math.round(remainingTime));
      if (store.state.updateTime) {
        store.state.updateTime = false;
        setTimeout(() => {
          store.state.remaining_time = remainingTime;
          store.state.updateTime = true;
        }, 1000);
      }

      if (percentCompleted < 100) {
        store.state.uploaded = false;
      }
    },
  };

  const api_url = "https://transfer.oeilabsolu.ch/api";
  // const api_url = "http://localhost:3334/api";
  store.state.uploading = true;
  store.state.starting_time = new Date().getTime();
  const res = await axios
    .post(api_url, formData, config)
    .then((res) => {
      store.state.uploading = false;

      if (res.status == 200) {
        store.state.uploaded = true;
      }
    })
    .catch((err) => {
      store.state.uploading = false;

      let error_message = err.response.data;
      if (!error_message) {
        error_message =
          "Une erreur inattendue est survenue, veuillez réessayer. Si l'erreur persiste, veuillez nous contacter à antoine@oeilabsolu.ch.";
      }
      // console.log(error_message);
      displayError(error_message);
    });

  // change status to indicate the success of the upload request
  // file.status = response.ok;
  // console.log(response);
  // return response;
}
