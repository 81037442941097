import { UploadableFile } from "@/classes/UploadableFile";
import router from "@/router";
import store from "@/store";
import { computed } from "vue";

export const fileExists = (files: UploadableFile[], othername: string) => {
  return files.some(({ filename }) => filename === othername);
};

export const formatSize = (size: number) => {
  const units = ["o", "Ko", "Mo", "Go"];

  let i = 0;

  while (size > 1024) {
    i++;
    size /= 1024;
  }
  return formatWithNDecimal(size, 2) + " " + units[i];
};

export const formatWithNDecimal = (number: number, n: number) => {
  return Math.round(number * Math.pow(10, n)) / Math.pow(10, n);
};

export const validMail = (mail: string) => {
  if (mail.length < 1) {
    return false;
  }
  if (!(mail.includes("@") && mail.includes("."))) {
    return false;
  }
  if (!(mail.lastIndexOf(".") > mail.indexOf("@") + 1)) {
    return false;
  }
  if (mail.includes(" ")) {
    return false;
  }

  if (mail.lastIndexOf(".") == mail.length - 1) {
    return false;
  }

  return true;
};

export const validPass = () => {
  if (
    store.state.params.password.protected &&
    store.state.params.password.pass.length < 6
  ) {
    return false;
  }
  return true;
};

export const formatDateFromMillis = (d: number) => {
  const date = new Date(d);
  let hours = date.getHours().toString();
  hours = ("0" + hours).slice(-2);
  let minutes = date.getMinutes().toString();
  minutes = ("0" + minutes).slice(-2);

  let day = date.getDate().toString();
  day = ("0" + day).slice(-2);

  let month = (date.getMonth() + 1).toString();
  month = ("0" + month).slice(-2);

  const day_of_year = `${day}/${month}/${date.getFullYear()}`;

  return day_of_year + " " + hours + ":" + minutes;
};

export const displayError = (err: string) => {
  store.commit("reset_state");
  store.commit("addError", err);
  router.replace("/");
};

export const go_back_home = (replace = true) => {
  store.commit("reset_state");
  if (replace) {
    router.replace("/");
  } else {
    router.push("/");
  }
};

export const formatDurationFromSeconds = (m: number) => {
  if (m < 60) {
    return `${m}sec`;
  }
  const min = m / 60;
  if (min < 60) {
    return `${Math.floor(min)}min${(
      "0" + (Math.round(m) % 60).toString()
    ).slice(-2)}sec`;
  }

  return `${Math.floor(min / 60)}h${(
    "0" + (Math.round(min) % 60).toString()
  ).slice(-2)}min`;
};
