import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import DownloadView from "../views/DownloadView.vue";
import ErrorDownloadView from "../views/ErrorDownloadView.vue";
import UploadingView from "../views/UploadingView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/error",
    name: "erreur_dl",
    component: ErrorDownloadView,
  },
  {
    path: "/uploading",
    name: "uploading",
    component: UploadingView,
  },
  {
    path: "/d/:folder",
    name: "download",
    component: DownloadView,
  },
  { path: "/:pathMatch(.*)*", name: "Home", component: HomeView },
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
});

export default router;
