import { formatSize } from "@/compositions/functions";
import { ref, computed, Ref, ComputedRef, reactive } from "vue";

export class UploadableFile {
  file: File;
  id: string;
  url: string;
  filename: string;
  uploadPercentage = 0;
  uploaded = false;
  size: string;

  constructor(file: File) {
    this.file = file;
    this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`;
    this.url = URL.createObjectURL(file);
    this.filename = file.name;
    this.size = formatSize(file.size);
  }
}
